<template>
  <b-card class="mb-0">
    <b-row>
      <b-col cols="12" lg="6" md="6" sm="12" xs="12"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0" />
      <b-col cols="12" lg="12" md="12" sm="12" xs="12">
        <div class="d-flex align-items-center justify-content-between mb-1">
          <b-col class="d-flex" cols="12" lg="4" md="2" sm="2" xs="4">
            <v-select :options="options" label="pagination" v-model="perPage" />
          </b-col>
          <div class="d-flex align-items-center justify-content-end">
            <b-col cols="12" lg="" md="12" sm="4" xs="12" style="width: 40vh;">
              <b-form-input class="" v-model="search" type="search" placeholder="Search" />
            </b-col>
            <b-button variant="outline-primary" @click="$router.push('/departaments/create')">
              + Departament
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-table id="departamentTable" responsive show-empty :items="filterDepartments" :key="filterDepartments.id"
      :fields="fields" :per-page="perPage" :current-page="currentPage">
      <!-- Owner -->
      <template #cell(owner)="row">
        <div v-if="row.item.owner">
          <!-- O ID do b-avatar será "avatar-" seguido pelo ID da linha (row.item.id) -->
          <b-avatar :id="'avatar-' + row.item.id" class="mr-1" size="40" variant="info">
            <b-img v-if="row.item.owner.avatar" fluid :src="row.item.owner.avatar"></b-img>
            <div v-else>{{ row.item.owner.name.substr(0, 1).toUpperCase() }}</div>
          </b-avatar>

          <!-- O target da b-tooltip será o ID do b-avatar correspondente -->
          <b-tooltip :target="`avatar-${row.item.id}`">
            {{ row.item.owner.name }}
          </b-tooltip>
        </div>
      </template>

      <!-- Members -->
      <template #cell(members)="data">
        <b-dropdown no-caret boundary=".table-container" variant="outline">
          <template #button-content>
            <b-row v-if="data.item.members.length === 1">
              <b-avatar v-for="item in data.item.members" :key="item.id" variant="primary" size="40" :src="item.avatar">
                <b-img v-if="item.avatar != null && item.avatar != ''" fluid :src="item.avatar" />
                <div v-else>{{ item.name.substr(0, 1).toUpperCase() }}</div>
              </b-avatar>
            </b-row>
            <b-row v-else-if="data.item.members.length <= 3">
              <b-avatar-group size="40" variant="primary">
                <b-avatar v-for="item in data.item.members" :key="item.id" :src="item.avatar">
                  <b-img v-if="item.avatar != null && item.avatar != ''" fluid :src="item.avatar" />
                  <div v-else>{{ item.name.substr(0, 1).toUpperCase() }}</div>
                </b-avatar>
              </b-avatar-group>
            </b-row>
            <b-row v-else>
              <b-avatar-group size="40" variant="primary">
                <b-avatar v-for="i in 3" :key="i">
                  <b-img v-if="data.item.members[i].avatar != null" fluid :src="data.item.members[i].avatar"></b-img>
                  <div v-else>
                    {{ data.item.members[i].name.substr(0, 1).toUpperCase() }}
                  </div>
                </b-avatar>
                <b-avatar :text="`+${data.item.members.length - 3}`"> </b-avatar>
              </b-avatar-group>
            </b-row>
          </template>
          <div style="max-height: 200px; overflow-y: auto;">
            <b-dropdown-item v-for="item in data.item.members" :key="item.id">
              <b-avatar :src="item.avatar" variant="primary" size="30" class="mr-1">
                <b-img v-if="item.avatar !== null && item.avatar !== ''" fluid :src="item.avatar" />
                <div v-else>{{ item.name.substr(0, 1).toUpperCase() }}</div>
              </b-avatar>
              {{ item.name }}
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </template>

      <!-- Actions -->
      <template #cell(actions)="row">
        <b-row>
          <b-col cols="2">
            <b-button class="p-0" v-b-tooltip.hover title="Edit" variant="flat" size="sm" @click="putRedirect(row)">
              <feather-icon icon="EditIcon" />
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button class="p-0" v-b-tooltip.hover title="Delete" variant="flat" size="sm" @click="clickModal(row)">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-table>

    <!-- Pagination -->
    <b-row align-h="end">
      <b-col md="12" class="d-flex align-items-center justify-content-end">
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
          aria-controls="departamentTable"></b-pagination>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BCardTitle,
  BTable,
  BCard,
  BButton,
  BRow,
  BCol,
  BIconTrash,
  BModal,
  BCollapse,
  BPagination,
  BForm,
  BContainer,
  BFormInput,
  BAvatar,
  BAvatarGroup,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BImg,
  VBTooltip
} from "bootstrap-vue";
import { departamentsHeaders } from "@/utils/headers.js";
import vSelect from 'vue-select'

export default {
  components: {
    BCardTitle,
    BCard,
    BTable,
    BButton,
    BRow,
    BCol,
    BIconTrash,
    BModal,
    BCollapse,
    vSelect,
    BPagination,
    BForm,
    BContainer,
    BAvatar,
    BAvatarGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BImg,
    VBTooltip
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      isModalVisible: false,
      fields: departamentsHeaders,
      search: "",
      perPage: 10,
      currentPage: 1,
      options: [
        10, 50, 100
      ],
    };
  },
  computed: {
    isDropdownVisible() {
      return this.$store.getters['dropdown/isDropdownVisible'];
    },

  },
  async created() {
    await this.refetchData();
  },
  computed: {
    departaments() {
      return this.currentUser.roles[0].name === 'HEAD_OF_ORGANIZATION' ?
        this.$store.getters.orgDepartments(this.currentUser.id) :
        this.$store.state.departamentConfig.departaments
    },
    currentUser() {
      return this.$store.state.usersConfig.currentUser
    },
    filterDepartments() {
      return this.departaments.filter((departament) => {
        return departament.title.toLowerCase().includes(this.search.toLowerCase())
      });
    },
    rows() {
      return this.filterDepartments.length
    }
  },
  methods: {
    toggleDropdown() {
      this.$store.dispatch('dropdown/toggleDropdown');
    },
    async refetchData() {
      await this.$store.dispatch("getAllDepartaments");
    },
    putRedirect(row) {
      this.$router.push("/departaments/" + row.item.id + "/update");
    },
    async deleteDepartaments(id) {
      await this.$store.dispatch("deleteDepartament", id);
      await this.refetchData();
    },
    clickModal(row) {
      this.row = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete that row.", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.deleteDepartaments(row.item.id);
          }
        });
    },

  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
